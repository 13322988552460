////////// SIDEBAR ////////
$sidebar-width-lg: 255px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: #ffffff;
$sidebar-light-menu-color: #888888;
$sidebar-light-submenu-color: #656565;
$sidebar-light-menu-active-bg: initial;
$sidebar-light-menu-active-color: #5b5b5b;
$sidebar-light-menu-hover-bg: $content-bg;
$sidebar-light-menu-hover-color: #56595a;
$sidebar-light-submenu-hover-color: $black;
$sidebar-light-submenu-active-color: $black;
$sidebar-light-menu-icon-color: #888888;
$sidebar-light-menu-arrow-color: #686868;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-dark-bg: #2c2e3e;
$sidebar-dark-menu-color: #8e94ac;
$sidebar-dark-menu-arrow-color: #8e94ac;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color:$white;
$sidebar-dark-menu-hover-bg: #282a3c;
$sidebar-dark-menu-hover-color: #fff;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #eae7f0;
$sidebar-dark-submenu-active-color: #eae7f0;
$sidebar-dark-menu-icon-color: #8e94ac;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #8d9498;

$sidebar-menu-font-size: 0.937rem;
$sidebar-menu-padding-top: .75rem;
$sidebar-menu-padding-right: 1.875rem;
$sidebar-menu-padding-bottom: .75rem;
$sidebar-menu-padding-left: 1.25rem;

$sidebar-submenu-padding: .25rem 0 0 3.75rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: .75rem;

$sidebar-icon-font-size:  1rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1rem;

$sidebar-box-shadow: 6px 16px 31px -18px #b7bcd1;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: $white;
$navbar-height: 65px;
$navbar-menu-color: #000;
$navbar-font-size: .875rem;
$navbar-icon-font-size: .9375rem;

///////// NAVBAR ////////